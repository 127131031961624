import 'keen-slider/keen-slider.min.css'
import '@assets/styles.scss'
import { appWithTranslation } from "next-i18next";
import {FC, PropsWithChildren, useEffect} from 'react'
import type { AppProps } from 'next/app'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { useRouter } from 'next/router'
declare const ga: any;
declare const window: any;
import AOS from "aos";
const Noop: React.FC<PropsWithChildren> = ({ children }) => <>{children}</>
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

// log the pageview with their URL
const pageview = (url: any) => {
  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  })
  window.fbq('track', 'PageView')
}

export default appWithTranslation(function MyApp({ Component, pageProps}: AppProps) {

  const Layout = (Component as any).Layout || Noop
  const router = useRouter()

  useEffect(() => {

    // here you can add your aos options
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 50,
    });

    document.body.classList?.remove('loading');
    const handleRouteChange = (url: any) => {
      /*pageview(url)*/
      window.scrollTo(0, 0);
    }

    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }

  }, [router.events])

  return (
    <>
      <Head />
      <ManagedUIContext>
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </ManagedUIContext>
    </>
  )
})
